<script setup>
import { ref, onMounted } from 'vue'
import { supabase } from '@/supabase'

const loading = ref(false)
const email = ref('')
const errorMessage = ref('') // Variable pour stocker le message d'erreur
const user = ref(null) // Variable pour stocker l'utilisateur connecté
const successMessage = ref('') // Variable pour stocker le message de succès

// Fonction pour gérer la connexion
const handleLogin = async () => {
  try {
    errorMessage.value = '' // Réinitialise le message d'erreur
    successMessage.value = '' // Réinitialise le message de succès
    loading.value = true

    // Validation de l'email
    if (!email.value) {
      errorMessage.value = 'Veuillez entrer une adresse e-mail.'
      return
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex pour valider l'email
    if (!emailRegex.test(email.value)) {
      errorMessage.value = 'Veuillez entrer une adresse e-mail valide.'
      return
    }

    const { error } = await supabase.auth.signInWithOtp({
      email: email.value,
    })

    if (error) {
      errorMessage.value = error.message
      throw error // Relance l'erreur pour terminer la fonction
    }

    successMessage.value = 'Vérifiez votre email pour le lien de connexion !' // Message de succès

    // Réinitialiser le champ e-mail
    email.value = '';
  } catch (error) {
    if (error instanceof Error) {
      errorMessage.value = error.message // Affiche tout autre message d'erreur
    }
  } finally {
    loading.value = false
  }
}

// Vérifie si l'utilisateur est déjà connecté au chargement de la page
onMounted(async () => {
  const { data } = await supabase.auth.getUser() // Ignore l'erreur
  if (data?.user) {
    user.value = data.user // Assigne l'utilisateur connecté
  }
})
</script>

<template>
  <main class="main">
    <div class="main__container">
      <div class="main__hero" v-if="user">
        <h2>Bienvenue, {{ user.email }} !</h2>
      </div>
      <div v-else>
        <form class="auth-form" @submit.prevent="handleLogin">
          <div class="form-widget">
            <h3 class="header">Inscription / Connexion</h3>
            <p class="description">Connectez-vous via un lien magique avec votre email ci-dessous.</p>
            <div>
              <input class="inputField" placeholder="Votre email" v-model="email" />
            </div>
            <div>
              <input
                type="submit"
                class="button block"
                :value="loading ? 'Chargement...' : 'Envoyer le lien magique'"
                :disabled="loading"
              />
            </div>
            <!-- Message d'erreur affiché en rouge et petit texte sous le bouton -->
            <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
          </div>
        </form>
        <!-- Message de succès affiché après l'envoi du lien magique -->
        <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
      </div>
    </div>
  </main>
</template>

<style scoped>
.main {
    flex: 1;
    display: flex;
    justify-content: center;
}

.main__container {
    width: 100%;
    max-width: 1120px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    padding: 20px 10px;
}

.auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
    background-color: rgb(var(--secondary-color),0.4);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.description {
    font-size: 0.9em;
    margin-bottom: 20px;
}

.inputField {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}

.inputField:focus {
    border-color: #007bff;
    outline: none;
}

.button {
    padding: 10px;
    background-color: rgb(var(--primary-color));
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition-duration: 300ms;
}

.button:disabled {
    background-color: #a9a9a9;
    cursor: not-allowed;
}

.button:hover:not(:disabled) {
    background-color: rgb(var(--primary-color), 0.7);
}

.error-message {
    color: red;
    font-size: 0.875em;
    margin-top: 8px;
}

.success-message {
    color: green; /* Couleur du message de succès */
    font-size: 0.875em;
    margin-top: 8px;
}

.footer {
    text-align: center;
    padding: 10px;
    background-color: #f1f1f1;
    position: relative;
}
</style>
