<template>
  <main class="main">
    <div class="main__container">
      <div class="main__hero">
        <h1>Générez votre IFU Degiro,<br>en deux clics.</h1>
        <p>Gagnez du temps et de l'argent en évitant les erreurs lors de votre déclaration aux impôts.</p>
        <button type="button" class="main__hero-button">Générer mon IFU</button>
      </div>

      <div class="main__features">
        <div class="main__feature main__feature--get">
          <img src="@/assets/report.svg" alt="Dépot Rapport Annuel Degiro" class="main__feature-icon">
          <h3 class="main__feature-title">Déposez votre rapport<br> annuel Degiro.</h3>
        </div>

        <div class="main__feature main__feature--get">
          <img src="@/assets/document.svg" alt="Obtenez IFU" class="main__feature-icon">
          <h3 class="main__feature-title">Obtenez votre IFU<br> en quelques secondes.</h3>
        </div>

        <div class="main__feature main__feature--make">
          <img src="@/assets/pc.svg" alt="Faire Declaration" class="main__feature-icon">
          <h3 class="main__feature-title">Faites votre déclaration<br> sereinement.</h3>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style scoped>
.main {
  flex: 1;
  display: flex;
  justify-content: center;
}

.main__container {
  width: 100%;
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  padding: 20px 10px;
  padding-top: 100px;
}

.main__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
}

.main__hero-button {
  padding: 10px;
  font-size: var(--body-font-size);
  color: rgb(var(--secondary-color));
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 300ms;
  background-color: rgb(var(--primary-color));
}

.main__hero-button:hover {
  background-color: rgb(var(--primary-color), 0.7);
}

.main__features {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Trois colonnes égales */
  gap: 20px; /* Espacement entre les items */
  justify-items: center; /* Centrer les items horizontalement */
  margin: 50px 0; /* Marges autour de la section */
}

.main__feature {
  background-color: rgb(var(--secondary-color));
  color: rgb(var(--primary-color));
  justify-content: center;
  align-items: center;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.main__feature-icon {
  max-width: 90px;
  height: auto;
  margin-bottom: 15px; /* Espace sous l'icône */
}

/* Styles pour les petits écrans */
@media (max-width: 768px) {
  .main__features {
    grid-template-columns: repeat(1, 1fr);
  }

  .main__feature {
    padding: 20px; /* Réduire le padding pour les petits écrans */
  }

  .main__hero-button {
    padding: 12px; /* Augmenter le padding pour un meilleur confort */
    font-size: var(--body-font-size); /* Ajuster la taille de la police si nécessaire */
  }

  h1 {
    font-size: 1.5rem; /* Ajuster la taille du titre */
  }

  p {
    font-size: 1rem; /* Ajuster la taille du paragraphe */
  }
}
</style>
