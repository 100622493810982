<template>
  <footer class="footer">
    <nav class="footer-nav">
      <a href="#">Politique de confidentialité</a>
      <a href="#">Contact</a>
      <a href="#">Mentions légales</a>
    </nav>
    <p class="year">©2024 MYIFU.fr</p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
.footer {
  background-color: black;
  width: 100%;
  height: auto; /* Changez la hauteur en auto pour un footer flexible */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centers content vertically */
  text-align: center;
  color: whitesmoke;
  padding: 20px 0; /* Ajout de padding pour un espacement agréable */
}

.footer-nav {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1120px;
  margin-bottom: 15px; /* Réduction de la marge pour moins d'espace */
  flex-wrap: wrap; /* Permet le wrapping pour les petits écrans */
}

.footer-nav a {
  margin: 0 10px;
  color: whitesmoke;
  text-decoration: none;
}

.footer-nav a:hover {
  text-decoration: underline;
}

.year {
  margin-top: 5px; /* Espacement au-dessus de l'année */
  margin-bottom: 0; /* Enlève l'espace en bas pour une apparence propre */
}

/* Media query pour les appareils mobiles */
@media (max-width: 600px) {
  .footer {
    padding: 20px 0; /* Ajout de padding pour le mobile */
  }

  .footer-nav {
    flex-direction: column; /* Empile les liens de navigation verticalement */
    align-items: center; /* Centre les éléments verticalement */
    margin-bottom: 15px; /* Ajustement de la marge */
  }

  .footer-nav a {
    margin: 5px 0; /* Espacement entre les liens */
  }

  .year {
    margin-top: 10px; /* Ajustement de l'espacement pour l'année */
  }
}
</style>
