<template>
  <div>
    <h1>About Page</h1>
    <p>Voici la page à propos de cette application.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutView'
};
</script>
