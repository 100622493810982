<template>
  <div id="app">
    <!-- Ajout du Header global -->
    <app-header />

    <!-- Contenu principal : pages rendues par Vue Router -->
    <router-view></router-view>

    <!-- Ajout du Footer global -->
    <app-footer />
  </div>
</template>

<script>
// Importer les composants Header et Footer
import AppHeader from "@/components/app-header.vue";
import AppFooter from "@/components/app-footer.vue";

export default {
  name: "App",
  components: {
    'app-header': AppHeader,  // Enregistrement du header
    'app-footer': AppFooter,  // Enregistrement du footer
  },
};
</script>

<style>
/* Styles globaux pour la navigation */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ajout pour une meilleure gestion des boîtes */
}

#app{
  flex: 1;
  display: flex;
  flex-direction: column;
}

html, body {
  height: 100%; /* Prend toute la hauteur de la fenêtre */
  font-family: var(--roboto);
  display: flex;
  flex-direction: column;
}

:root {
  --roboto: "Roboto", sans-serif;
  --primary-color: 18, 56, 57;
  --secondary-color: 253, 233, 186;

  --headline-font-size: 36px;
  --headline-font-weight: 700;

  --subheadline-font-size: 36px;
  --subheadline-font-weight: 400;

  --body-font-size: 18px;
}


</style>
