<template>
  <header class="header">
    <nav class="header__nav">
      <router-link to="/" class="nav__logo" exact @click="closeMenu">
        <img src="@/assets/logo.svg" alt="Logo" class="nav__logo-img" />
        <h3 class="nav__logo-title">myIFU</h3>
      </router-link>
      <button class="nav__burger" @click="toggleMenu">
        &#9776; <!-- Icône de menu burger -->
      </button>
      <div :class="['nav__auth', { 'nav__auth--open': menuOpen }]">
        <router-link to="/signup" class="nav__auth-button nav__auth-button--signin" @click="closeMenu">
          Connexion
        </router-link>
        <router-link to="/blog" class="nav__auth-button nav__auth-button--blog" @click="closeMenu">
          Blog
        </router-link>
        <router-link to="/tarifs" class="nav__auth-button nav__auth-button--tarifs" @click="closeMenu">
          Tarifs
        </router-link>
        <router-link to="/generer" class="nav__auth-button nav__auth-button--signup" @click="closeMenu">
          Générer mon IFU
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "app-header",
  data() {
    return {
      menuOpen: false, // État du menu
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen; // Basculer l'état du menu
    },
    closeMenu() {
      this.menuOpen = false; // Fermer le menu
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 75px;
  background-color: rgb(var(--primary-color));
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__nav {
  width: 100%;
  max-width: 1120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.nav__logo {
  display: flex;
  gap: 0px;
  align-items: center;
  text-decoration: none; /* Enlève le soulignement du lien */
}

.nav__logo-img {
  width: 66px;
  height: auto;
  cursor: pointer;
}

.nav__logo-title {
  font-size: var(--subheadline-font-size);
  cursor: pointer;
  color: rgb(var(--secondary-color));
}

.nav__auth {
  display: flex;
  gap: 10px;
  font-size: var(--body-font-size);
}

.nav__burger {
  display: none; /* Masqué par défaut */
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Styles pour le menu en mode burger */
.nav__auth--open {
  display: flex;
  flex-direction: column;
  position: absolute; /* Positionné pour le menu burger */
  top: 75px; /* Sous la barre de navigation */
  left: 0;
  right: 0;
  background-color: rgb(var(--primary-color));
  padding: 10px;
}

/* Styles pour les boutons du menu */
.nav__auth-button--blog, .nav__auth-button--signin, .nav__auth-button--tarifs {
  padding: 10px;
  font-size: var(--body-font-size);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 300ms;
  color: rgb(var(--secondary-color));
  text-decoration: none; /* Enlève le soulignement du lien */
}

.nav__auth-button--signup{
  padding: 10px;
  font-size: var(--body-font-size);
  border: none;
  border-radius: 10px;
  background-color: rgb(var(--secondary-color));
  cursor: pointer;
  transition: all 300ms;
  color: rgb(var(--primary-color));
  text-decoration: none; /* Enlève le soulignement du lien */
}

.nav__auth-button--blog:hover, .nav__auth-button--signin:hover, .nav__auth-button--tarifs:hover {
  color: rgb(var(--secondary-color), 0.7);
}

.nav__auth-button--signup:hover {
  background-color: rgb(var(--secondary-color), 0.7);
}

/* Styles pour afficher le burger sur les petits écrans */
@media (max-width: 768px) {
  .nav__auth {
    display: none; /* Cacher les boutons en mode desktop */
    flex-direction: column;
  }

  .nav__burger {
    display: block; /* Afficher le bouton burger */
  }

  .nav__auth--open {
    display: flex; /* Afficher le menu lorsqu'il est ouvert */
    justify-content: center;
    align-items: center;
  }
}
</style>
