import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import AboutView from '@/views/AboutView.vue';
import SignUpView from '@/views/authView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUpView
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
